<template>
  <div class="scheme">
    <BaseDialog
      title="使用医嘱套添加"
      :isshow="visible"
      @handleShow="handleShow"
      width="80%"
      height="auto"
    >
      <div class="schemeBox flex-start">
        <div class="scheme-left">
          <el-input v-model="searchSetUp.search_code" placeholder="搜索套组">
            <template #suffix><i class="fa fa-search"></i></template>
          </el-input>
          <div class="scheme-cont">
            <el-scrollbar>
              <div
                class="item"
                v-for="item in list"
                :key="item.id"
                @click="setUpBut(item)"
                :class="item.id === actSetUpData.id ? 'active' : ''"
              >
                <div class="lable ellipsis">{{ item.group_name }}</div>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div class="contents">
          <el-table
            v-loading="tableLoading"
            :data="tableData"
            border
            size="small"
            @select="tableSelect"
            @select-all="tableSelectAll"
            max-height="550px"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column
              width="90"
              prop="dict_advice_type_name"
              label="类别"
            />
            <el-table-column prop="advice_content" label="医嘱内容" />
            <el-table-column width="90" label="透中用药">
              <template #default="scope">
                {{ scope.row.drug_type === 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column width="90" label="个人库存">
              <template #default="scope">
                {{ scope.row.self_provided === 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column width="90" label="院外用药">
              <template #default="scope">
                {{ scope.row.is_preopen === 1 ? '是' : '否' }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            class="color-determine"
            type="primary"
            :loading="saveLoading"
            @click="saveLongItem"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import BaseDialog from '@/components/Dialog/index.vue'
import service from '@/utils/request'
import { ElMessage } from 'element-plus'
export default {
  name: 'dialysisScheme',
  components: {
    BaseDialog,
  },
  props: {
    patientId: {
      type: String,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  emits: ['refreshDataList'],
  setup(props, ctx) {
    const state = reactive({
      dataForm: {},
      searchSetUp: {},
      actSetUpData: {},
      list: [],
      tableData: [],
      tableCheData: [],
      visible: false,
      tableLoading: false,
      saveLoading: false,
    })

    const initData = () => {
      state.visible = true
      initDatas()
    }

    const initDatas = async () => {
      await getDataList()
      if (state.list.length > 0) {
        if (!state.actSetUpData.id) {
          state.actSetUpData = state.list[0]
        }
        getAdviceGroupList()
      }
    }
    const getDataList = async () => {
      let res = await service.post('/api/advice_group/list', state.searchSetUp)
      if (res.code === 0) {
        state.list = res.data.records
      }
    }
    const setUpBut = (item) => {
      state.tableCheData = []
      state.actSetUpData = item
      getAdviceGroupList(item.id)
    }
    const getAdviceGroupList = async () => {
      state.tableLoading = true
      let res = await service.post('/api/advice_group_item/list', {
        group_id: state.actSetUpData.id,
      })
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data
      }
    }

    const tableSelect = (row) => {
      state.tableCheData = row
    }
    const tableSelectAll = (row) => {
      state.tableCheData = row
    }

    const handleShow = (val) => {
      state.visible = val
    }

    const saveLongItem = async () => {
      if (state.tableCheData.length <= 0) {
        ElMessage.error('请先选择医嘱套')
        return
      }
      let row = []
      state.tableCheData.map((item) => {
        row.push(item.id)
      })
      let ruleForm = {
        patient_id: props.patientId,
        ids: row,
      }
      state.saveLoading = true
      let url = ''
      if (props.type == 2) {
        url = '/api/dialysis_record/create_tmp_multiple'
      } else {
        url = '/api/advice/batch'
      }
      let res = await service.post(url, ruleForm)
      state.saveLoading = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        state.visible = false
        ctx.emit('refreshDataList')
      }
    }

    return {
      ...toRefs(state),
      initData,
      handleShow,
      saveLongItem,
      setUpBut,
      tableSelect,
      tableSelectAll,
      getDataList,
    }
  },
}
</script>

<style scoped lang="scss">
.schemeBox {
  margin-top: 24px;
}
.scheme-left {
  width: 280px;
  margin-right: 30px;
}

.contents {
  flex: 1;
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;
  .color-cancel {
    background: #ffffff;
    color: #3166ae;
    margin-right: 10px;
  }
}
.scheme-cont {
  padding: 0 10px;
  margin-top: 20px;
  border-right: 1px solid #e0e0e0;
  height: 500px;
  .item {
    height: 32px;
    line-height: 32px;
    width: 244px;
    font-size: 14px;
    font-family: 'Source Han Sans CN-Medium';
    color: #444444;
    padding-right: 14px;
    margin-bottom: 12px;
    .lable {
      background-color: #ffffff;
      border-radius: 8px 8px 8px 8px;
      padding-left: 20px;
      cursor: pointer;
    }
    &.active {
      border-right: 4px solid #3166ae;
      .lable {
        background-color: rgba(49, 102, 174, 0.3);
        color: #3166ae;
      }
    }
    &:hover {
      .lable {
        background-color: rgba(49, 102, 174, 0.3);
        color: #3166ae;
      }
    }
  }
}
</style>
