<template>
  <div class="patentInfo">
    <el-scrollbar>
      <div class="cont">
        <div class="cont-main col999">
          <div class="updateBox flex-between">
            <div class="type">临时医嘱</div>
          </div>
          <div class="tables">
            <el-table
              size="small"
              v-loading="tableLoading"
              row-key="id"
              :data="tableData"
              border
            >
              <el-table-column width="100" prop="use_type" label="用药方法" />
              <el-table-column width="120" prop="standard" label="规格" />
              <el-table-column width="60" prop="dosage" label="计量" />
              <el-table-column width="90" prop="dosage_unit" label="计量单位" />
              <el-table-column width="70" prop="frequency" label="频度" />
              <el-table-column width="70" prop="num" label="用量" />
              <el-table-column width="90" prop="doctor_name" label="医生名" />
              <el-table-column
                show-overflow-tooltip
                prop="remark"
                label="备注"
              />
              <el-table-column width="70" prop="frequency" label="频度" />
              <el-table-column width="160" prop="start_time" label="开嘱时间" />
              <el-table-column width="160" prop="end_time" label="结束时间" />
              <el-table-column
                width="90"
                prop="do_user_id_name"
                label="执行护士"
              />
              <el-table-column width="150" label="执行时间">
                <template #default="scope">
                  <el-time-picker
                    v-model="scope.row.do_time"
                    disabled
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    placeholder="执行时间"
                  >
                  </el-time-picker>
                </template>
              </el-table-column>
              <el-table-column
                width="90"
                prop="check_user_id_name"
                label="核对人"
              />
              <el-table-column width="150" label="核对时间">
                <template #default="scope">
                  <el-time-picker
                    v-model="scope.row.check_time"
                    disabled
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    placeholder="执行时间"
                  >
                  </el-time-picker>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-scrollbar>

    <BaseDialog
      title="修改记录"
      :isshow="isHistory"
      @handleShow="
        (val) => {
          isHistory = val
        }
      "
      width="84%"
      height="auto"
    >
      <el-table
        size="small"
        :data="historyTable"
        border
        style="margin-top: 20px"
      >
        <el-table-column
          fixed
          width="160px"
          prop="updated_time"
          label="修改时间"
        />
        <el-table-column fixed prop="doctor.name" label="修改人" />
        <el-table-column fixed prop="type_content" label="类别" />
        <el-table-column width="100px" prop="id" label="数据ID" />
        <el-table-column width="300px" prop="advice_content" label="医嘱内容" />
        <el-table-column
          width="150px"
          prop="dict_advice_type"
          label="医嘱类型编码"
        />
        <el-table-column
          width="150px"
          prop="dict_advice_type_name"
          label="医嘱类型名称"
        />
        <el-table-column width="150px" prop="dict_drug" label="药品编码" />
        <el-table-column width="120px" prop="drug" label="药品名称" />
        <el-table-column width="100px" prop="standard" label="规格" />
        <el-table-column width="90px" prop="dosage" label="单次用量" />
        <el-table-column
          width="140px"
          prop="dict_dosage_unit"
          label="单次用量单位"
        />
        <el-table-column
          width="150px"
          prop="dict_use_type"
          label="用药途径编码"
        />
        <el-table-column width="150px" label="用药途径名称" />
        <el-table-column width="120px" prop="dict_frequency" label="执行频次" />
        <el-table-column width="90px" prop="num" label="数量" />
        <el-table-column width="90px" prop="num_unit_name" label="单位" />
        <el-table-column width="160px" label="是否按收费组套收费" />

        <el-table-column width="120px" label="收费组套ID" />
        <!-- <el-table-column
          width="150px"
          prop="charge_group_name"
          label="收费组套名称"
        /> -->
        <el-table-column width="150px" label="收费组套名称" />
        <el-table-column width="210px" label="记录当前组套下选择的项目ID" />
        <el-table-column width="120px" prop="self_provided" label="个人库存" />
        <el-table-column width="120px" prop="start_time" label="开嘱时间" />
        <el-table-column width="120px" prop="do_time" label="执行时间" />
        <el-table-column width="120px" prop="check_time" label="核对时间" />
        <el-table-column
          width="120px"
          prop="create_doctor_id"
          label="开嘱医生ID"
        />
        <el-table-column
          width="140px"
          prop="create_doctor_id_name"
          label="开嘱医生姓名"
        />
        <el-table-column width="120px" prop="do_user_id" label="执行护士Id" />
        <el-table-column
          width="120px"
          prop="do_user_id_name"
          label="执行护士姓名"
        />
        <el-table-column
          width="120px"
          prop="check_user_id"
          label="核对护士ID"
        />
        <el-table-column
          width="120px"
          prop="check_user_id_name"
          label="核对护士姓名"
        />
        <el-table-column
          width="120px"
          prop="dict_drug_type"
          label="药品类型编码"
        />
        <el-table-column
          width="140px"
          prop="dict_drug_type_name"
          label="药品类型名称"
        />
        <!-- <el-table-column width="120px" prop="advice_content" label="执行标识" />
        <el-table-column width="120px" prop="advice_content" label="核对标识" />
        <el-table-column width="120px" prop="advice_content" label="更新标识" /> -->
        <!-- <el-table-column
          width="150px"
          prop="advice_content"
          label="是否外部导入数据"
        /> -->
        <el-table-column
          width="140px"
          prop="source_name"
          label="数据来源类型"
        />
        <el-table-column width="150px" label="开嘱医生签名图片" />
        <el-table-column width="150px" label="执行护士签名图片" />
        <el-table-column width="150px" label="核对护士签名图片" />
        <!-- <el-table-column
          width="180px"
          prop="advice_content"
          label="标识属于同一组套的颜色"
        /> -->
        <el-table-column
          width="120px"
          prop="advice_group_id"
          label="医嘱组套ID"
        />
        <el-table-column
          width="120px"
          prop="advice_group_name"
          label="医嘱组套名称"
        />
        <el-table-column
          width="160px"
          prop="is_custom"
          label="是否自定义编辑医嘱"
        />
      </el-table>
    </BaseDialog>

    <batchLongIerm
      v-if="isBatchLongIerm"
      ref="batchLongIermRef"
      :patientId="patientId"
      :type="2"
      @refreshDataList="getDataList"
    ></batchLongIerm>
    <addLongIerm
      v-if="isAddLongIerm"
      ref="addLongIermRef"
      :patientId="patientId"
      @refreshDataList="getDataList"
    ></addLongIerm>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, nextTick, watch } from 'vue'
import service from '@/utils/request'
import { getConstHei, clone } from '@/utils/tool.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import batchLongIerm from '@/views/patient/Details/advice/batchLongIerm.vue'
import addLongIerm from './addLongIerm.vue'
import BaseDialog from '@/components/Dialog/index.vue'
import dayjs from 'dayjs'
export default {
  name: 'programme',
  components: {
    addLongIerm,
    batchLongIerm,
    BaseDialog,
  },
  props: ['patientData', 'searchListData'],
  emits: ['stepsUpdate'],
  setup(props, ctx) {
    const state = reactive({
      ruleForm: {},
      infoHei: '',
      searchData: {
        date: '2022-3-15',
      },
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      tableData: [],
      historyTable: [],
      isHistory: false,
      isBatchLongIerm: false,
      batchLongIermRef: null,
      isAddLongIerm: false,
      addLongIermRef: null,
      tableLoading: false,
      patientId: 0,
      record_id: 0,
    })

    watch(
      () => props.patientData.patient_id,
      (val) => {
        console.log(val)
        if (val) {
          state.patientId = val
          state.record_id = props.patientData.record_id
        } else {
          state.patientId = 0
          state.record_id = 0
        }
        state.searchData.date = props.searchListData.time
        getDataList()
      }
    )

    onMounted(() => {
      state.infoHei = getConstHei()
      if (props.patientData.patient_id) {
        state.patientId = props.patientData.patient_id
      } else {
        state.patientId = 0
      }
      state.searchData.date = props.searchListData.time
      getDataList()
    })
    const getDataList = async () => {
      state.searchData.patient_id = state.patientId
      state.searchData.record_id = state.record_id
      state.tableLoading = true
      let res = await service.post(
        '/api/patient/get_tmp_patient_advice_list',
        state.searchData
      )
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data.records
        state.pageData.totalSum = res.data.total
      }
    }

    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getDataList()
    }
    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getDataList()
    }

    const modParient = (item) => {
      state.isAddLongIerm = true
      nextTick(() => {
        state.addLongIermRef.initData(item)
      })
    }
    const delParient = (item) => {
      ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post('/api/dialysis_record/tmp_delete', {
            id: item.id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
          }
        })
        .catch(() => {})
    }
    const adviceDoChe = async (val, row) => {
      const item = clone(row)
      if (!val) {
        let res = await service.post('/api/dialysis_record/cancel_do_tmp', {
          id: item.id,
        })
        if (res.code === 0) {
          ElMessage.success(res.msg)
          ctx.emit('stepsUpdate')
        }
        getDataList()
      } else {
        if (!item.do_time) {
          item.do_time = dayjs(new Date()).format('HH:mm:ss')
        }
        let res = await service.post('/api/dialysis_record/do_tmp', {
          id: item.id,
          do_time: item.do_time,
        })
        if (res.code === 0) {
          ElMessage.success(res.msg)
          ctx.emit('stepsUpdate')
        }
        getDataList()
      }
    }
    const adviceCheckChe = async (val, row) => {
      const item = clone(row)
      if (!val) {
        let res = await service.post('/api/dialysis_record/cancel_check_tmp', {
          id: item.id,
        })
        if (res.code === 0) {
          ElMessage.success(res.msg)
        }
        getDataList()
      } else {
        if (!item.check_time) {
          item.check_time = dayjs(new Date()).format('HH:mm:ss')
        }
        let res = await service.post('/api/dialysis_record/check_tmp', {
          id: item.id,
          check_time: item.check_time,
        })
        if (res.code === 0) {
          ElMessage.success(res.msg)
        }
        getDataList()
      }
    }
    const historyParient = async (item) => {
      let res = await service.post('/api/dialysis_record/get_his_list', {
        data_id: item.id,
      })
      if (res.code === 0) {
        state.historyTable = res.data.records
        state.isHistory = true
      }
    }

    const openWeight = (type) => {
      if (!state.patientId || state.patientId === 0) {
        ElMessage({
          message: '请先选择患者',
          type: 'warning',
        })
        return
      }
      switch (type) {
        case 'batchLongIerm':
          state.isBatchLongIerm = true
          nextTick(() => {
            state.batchLongIermRef.initData()
          })
          break
        case 'addLongIerm':
          state.isAddLongIerm = true
          nextTick(() => {
            state.addLongIermRef.initData()
          })
          break
      }
    }

    return {
      ...toRefs(state),
      openWeight,
      getDataList,
      handleSizeChange,
      handleCurrentChange,
      modParient,
      delParient,
      adviceDoChe,
      adviceCheckChe,
      historyParient,
    }
  },
}
</script>

<style scoped lang="scss">
.el-table {
  &:deep(.el-date-editor.el-input) {
    width: 120px;
  }
}
.updateBox {
  height: 37px;
  margin-bottom: 10px;
  margin-top: 20px;
  .el-radio-group {
    margin-right: 30px;
  }
}
.type {
  font-size: 18px;
  color: #000000;
  line-height: 32px;
}
.parient-tag {
  .el-tag {
    margin-right: 10px;
  }
}
.checkBox {
  position: relative;
  z-index: 2;
}
</style>
